import React from "react";
import { Container } from "@material-ui/core";
import ItemMenu from "./components/ItemMenu";

import useMenu from "./useMenu";
import {
  Main,
  MenuBox,
  WelcomeBox,
  WelcomeText,
  ContainerNotificacao,
  NotificacaoList,
} from "./Styled";

import ComunicacaoIcon from "./images/comunicacao.png";
import DocumentoIcon from "./images/documento.png";
import HoleriteIcon from "./images/pagamento.png";
import InformeIcon from "./images/informe.png";
import EadIcon from "./images/elearning.png";

const Menu = () => {
  const { notificationComunication, isFired } = useMenu();
  document.title = "Home | Grupo Altum";
  return (
    <>
      <Main>
        <Container>
          <WelcomeBox>
            <WelcomeText>Selecione um de nossos serviços.</WelcomeText>
          </WelcomeBox>
          <MenuBox isFired={isFired}>
            <ItemMenu
              key={1}
              label={"Comunicação Interna"}
              route={"comunicacao-interna"}
              iconImg={ComunicacaoIcon}
              visible={!isFired}
            />
            <ItemMenu
              key={2}
              label={"Documentos"}
              route={"documentos"}
              iconImg={DocumentoIcon}
              visible={!isFired}
            />
            <ItemMenu
              key={3}
              label={"Holerites"}
              route={"holerites"}
              iconImg={HoleriteIcon}
            />
            <ItemMenu
              key={4}
              label={"Informe de Rendimentos"}
              route={"informe-rendimentos"}
              iconImg={InformeIcon}
            />
            <ItemMenu
              key={5}
              label={"EAD"}
              route="ead"
              iconImg={EadIcon}
              visible={!isFired}
            />
          </MenuBox>
        </Container>
      </Main>

      {/* Notificações
      <ContainerNotificacao>
        <h2>Mensagens</h2>
        {notificationComunication.length > 0 &&
          notificationComunication.map((item, index) => {
            return (
              <NotificacaoList key={index} href={item.url}>
                <span>
                  {" "}
                  {`Resposta para solicitação ${item.comunicacaoId}`}
                </span>
              </NotificacaoList>
            );
          })}
      </ContainerNotificacao>
      */}
    </>
  );
};

export default Menu;
